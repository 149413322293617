<template>
	<div class="sort">
		<img src="~@/assets/img/sort.svg"/>
		<button
			class="show-modal"
			id="show-modal"
			@click="showList = !showList"
		>{{ placeholder }}
		</button>
		<DropList
			class="drop_list"
			v-if="showList"
			:data="list"
			@change="update($event)"
		/>
	</div>
</template>

<script>
export default {
	components: {
		DropList: () => import('@/components/block/Rest/DropList.vue')
	},
	props: ['label', 'list'],
	data()
	{
		return {
			placeholder: this.label,
			showList: false,
		}
	},
	methods: {
		update(data)
		{
			this.showList = false;
			this.placeholder = data.label;
			this.$emit('setSelected', data);
		}
	}
}
</script>


<style
	lang="scss"
	scoped
>
div .sort
{
	padding: 12px;
	border-radius: 10px;
	border: 1px solid;
	border-color: $greyBorder;
	display: grid;
	align-items: center;
	grid-template-rows: 35px auto;
	grid-template-columns: max-content 1fr;
	grid-template-areas:
                "img button"
                "dropList dropList";
	position: relative;

}

button
{
	border: none;
	outline: none;
	background-color: inherit;
	color: $greyText;
	grid-area: button;
	text-align: left;
}

.show-modal
{
	line-height: 3;
}

span
{
	text-align: center;
}

img
{
	margin: 5px 6px 5px 0;
	grid-area: img;
}

.drop_list
{
	grid-area: dropList;
	border: none;
	z-index: 1;
	color: $greyText;
	position: absolute;
	top: 25px;
	width: 100%;
}
</style>
